import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import SEO from "../components/seo";
import BlockContent from "@sanity/block-content-to-react";

/* Contact Page
============================================================================= */

const ContactPage = ({ data }) => {
	// Config
	const { sanityIsolaid } = data;
	const { _rawContactSection: contact } = sanityIsolaid;

	// Render
	return (
		<>
			<SEO title="Contact" />
			<div className="w-full">
				<div className="w-full flex lg:flex-row flex-col">
					<div className="lg:w-1/2 w-full pl-10 xl:pr-72 pr-12 lg:pt-24 pt-10 pb-4 flex flex-col">
						<h2 className="text-darkblue text-5xl font-sans font-black pb-6 ibm-plex">
							WANT TO GET IN TOUCH?
						</h2>
						<BlockContent
							className="block-content text-darkblue font-sans pb-6 ibm-plex"
							blocks={contact}
						/>
					</div>
					<div className="lg:w-1/2 w-full">
						<div className="w-full h-full bg-orange py-24 flex flex-col items-center justify-start">
							<form
								name="Contact"
								method="POST"
								action="/"
								data-netlify="true"
								className="w-full px-20"
							>
								<input
									type="hidden"
									name="form-name"
									value="Contact"
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="NAME"
									placeholder="NAME*"
									required
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="EMAIL"
									placeholder="EMAIL*"
									required
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="LINK"
									placeholder="LINK*"
									required
								/>
								<textarea
									className="mb-8 h-32 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									type="text"
									placeholder="MESSAGE*"
									name="MESSAGE"
								/>
								<div className="w-full flex items-center justify-end">
									<input
										className="cursor-pointer bg-beige border-0 w-64 font-black ibm-plex px-3 py-2  text-darkblue h-12 lg:text-base text-sm"
										type="submit"
										value="SEND"
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query ContactPageQuery {
		sanityIsolaid {
			_rawContactSection(resolveReferences: { maxDepth: 10 })
		}
	}
`;

/* Export
============================================================================= */

export default ContactPage;
